<template>
  <v-container>
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon large color="warning"> mdi-bell-ring </v-icon>
          LINK DOWNLOAD เอกสาร B2B
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-row no-gutters>
            <v-btn
              class="ma-2"
              outlined
              href="http://api.ntnorth.online/BCRM/BCRM_manual_rev2.pdf"
            >
              <v-icon color="warning" size="large"> mdi-information</v-icon>
              Download คู่มือBCRM
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              href="http://api.ntnorth.online/BCRM/Template-2567.7z"
            >
              <v-icon color="success" size="large"> mdi-information</v-icon>
              Template แบบฟอร์มอนุมัติการตลาด ภน.1 2567
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              href="http://api.ntnorth.online/BCRM/ตัวอย่างเอกสารส่งมอบ+หน้าปก.7z"
            >
              <v-icon color="success" size="large"> mdi-information</v-icon>
              Template เอกสารส่งมอบและหน้าปก ภน.1 2567
            </v-btn>
        </v-row>

    </v-card>

    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon large color="error"> mdi-bell-alert-outline </v-icon>
          ประกาศ
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    <br />
    <v-row no-gutters>

      <v-col cols="12" md="8" class="mx-15">
        <p style="color: green"> [9/9/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
         เพิ่มข้อมูลลูกค้า Active จากระบบ CRM ลูกค้ากลุ่ม  B [ลธผ.] ข้อมูล ณ วันที่ 31 สิงหาคม 2567 จำนวน 91,165 รายการ
        </h4> </v-col>

      <v-col cols="12" md="8" class="mx-15">
        <p style="color: black"> [2/9/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
         เพิ่มข้อมูลลูกค้า Active จากระบบ CRM [ลธผ.] ข้อมูล ณ วันที่ 31 กรกฎาคม 2567 จำนวน 46,988 รายการ 4 ประเภท (ลูกค้าวิสาหกิจขนาดกลางและขนาดย่อม,ลูกค้าราชการ,ลูกค้าองค์กรไม่แสวงหากำไร,ลูกค้าองค์กรธุรกิจขนาดใหญ่)
        </h4> </v-col>

      <v-col cols="12" md="8" class="mx-15">
        <p style="color: black"> [29/8/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
           Cleansing ข้อมูล All@Secure จำนวน 136 รายการ(แนบ Link Remote Firewall,ตรวจสอบข้อมูลลูกค้า ,Omada Cloud, etc)
        </h4>
      </v-col>
      <v-col cols="12" md="8" class="mx-15">
        <p style="color: black"> [11/6/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
         เพิ่มข้อมูลลูกค้า Active จากระบบ CRM [ลธผ.] ข้อมูล ณ วันที่ 31 พฤษภาคม 2567 จำนวน 92,286 รายการ 4 Type (ลูกค้าวิสาหกิจขนาดกลางและขนาดย่อม,ลูกค้าราชการ,ลูกค้าองค์กรไม่แสวงหากำไร,ลูกค้าองค์กรธุรกิจขนาดใหญ่)
        </h4>
      </v-col>
      <v-col cols="12" md="8" class="mx-15">
        <p style="color: black"> [3/4/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
         อัพเดทข้อมูลลูกค้า Special Bill ข้อมูลเดือนกุมภาพันธ์ 2567 จากระบบ สภน1.1  จำนวน 4,907 รายการ และสภน.1.2  จำนวน 2,499 รายการ 
        </h4>
      </v-col>
      <v-col cols="12" md="8" class="mx-15">
        <p style="color: black"> [5/3/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
         เพิ่มข้อมูลลูกค้า Active จากระบบ CRM [ลธผ.] ประจำเดือนมีนาคม 2567 จำนวน 92,907 รายการ 4 Type (ลูกค้าวิสาหกิจขนาดกลางและขนาดย่อม,ลูกค้าราชการ,ลูกค้าองค์กรไม่แสวงหากำไร,ลูกค้าองค์กรธุรกิจขนาดใหญ่)
        </h4>
      </v-col>
     
      <v-col cols="12" md="8" class="mx-15">
        <p style="color: black">[29/2/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
          รายงานผลกิจกรรม BCRM ครั้งที่ 1 <a href="../assets/สรุป รายงานผล BCRM ครั้งที่ 1.pdf">  Download</a>
        </h4>
       </v-col>
       <br />
      <v-col cols="12" md="8" class="mx-15">
        <p style="color: black"> [27/2/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
          หากท่านต้องการลบข้อมูล กรุณาขอสิทธิ์(admin จังหวัด)ได้ที่ admin
                  </h4>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
          ต้องการเพิ่มประเภทลูกค้าที่ต้องการ ติดต่อ admin 
          090-523-6564 ตภน.1(ภควัต)
        </h4>
      </v-col>
      <br />
      <v-col cols="12" md="8" class="mx-15">
        <p style="color: black"> [1/2/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
          ลูกค้าโครงการกลุ่มสถานศึกษา สังกัดสำนักงานส่งเสริมการเรียนรู้ (สกร.)
          และรพ.สต.สังกัด(กรมส่งเสริมการปกครองท้องถิ่น)ที่เป็นบิลรวมให้ใส่ที่ต้นสังกัด(จังหวัด)ที่เดียว
          เนื่องจากใช้ รหัสบัญชีลูกค้า(CA) และ รหัสบัญชีใบแจ้งหนี้(BA) ร่วมกัน
        </h4>
      </v-col>
      <br />
      <v-col cols="12" md="9" class="mx-15">
        <p style="color: black"> [31/1/2567]</p>
        <h4 class="font-weight-medium">
          <v-icon large color="error"> mdi-circle-small </v-icon>
          ข้อมูลลูกค้ากลุ่มเป้าหมาย อปท., สถานศึกษา, ส่วนงานสาธารณสุขของรัฐ
          มีอยู่แล้วในระบบ และโปรดค้นหาลูกค้าในระบบก่อน ADD เพิ่มทุกครั้ง
          ป้องกันการเพิ่มข้อมูลซ้ำซ้อน
        </h4>
      </v-col>
      <br />
    </v-row>
  </v-card>

    <v-footer align-self="end">
      <h6>
        <p class="text-right">
          Operate & Modify by Northern Region Marketing and Key Account
          Management Sector
        </p>
        <p class="text-right">
          Created by NTPLC Central Region Telecommunication Engineering Sector
        </p>
      </h6>
    </v-footer>
  </v-container>

  
</template>

<script>
  export default {
    name: 'Contact',
    props: {
      // msg: String,
    },

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
